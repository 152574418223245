/**
 * Created by fulle on 2024/02/26.
 */

import Vue from 'vue';
import Vuex from 'vuex';

import registration from "./modules/registrationStore";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        processing:0,
        messages:[],
        actionName:"",
        trigger_tooltip:false,
    },
    mutations: {
        START_PROCESSING: (state) =>
        {
            state.processing++;
            console.log("Processing - Start: "+state.processing);
        },
        STOP_PROCESSING: (state) =>
        {
            if(state.processing > 0)
            {
                state.processing--;
            }
            console.log("Processing - Stop: "+state.processing);
        },
        SET_MESSAGES: (state, _messages) => {
            console.log(JSON.stringify(_messages));
            state.messages = _messages;
        },
        SET_ACTION: (state, _value) =>
        {
            state.actionName = _value;
        },
        SET_TRIGGER_TOOLTIP(state, _value)
        {
            state.trigger_tooltip = _value;
        },
    },
    actions: {
        START_PROCESSING:({commit}) =>
        {
            commit('START_PROCESSING');
        },
        STOP_PROCESSING:({commit}) =>
        {
            commit('STOP_PROCESSING');
        },

        COMMIT_SET_ACTION:({commit},_value)=>
        {
            commit('SET_ACTION',_value);
        },

        COMMIT_SET_MESSAGES:({commit},messages)=>
        {
            console.log("SET MESSAGES TO "+JSON.stringify(messages));
            commit('SET_MESSAGES',messages);
        },

        SET_TRIGGER_TOOLTIP : ({commit}, _value) =>
        {
            commit("SET_TRIGGER_TOOLTIP",_value);
        },
        RESPONSE_PROCESS_AJAX_ERROR : ({commit}, _value) =>
        {
            window.console.log("Error* "+ JSON.stringify(_value));
            let _arrMsg = [];
            if(typeof _value.data !== "undefined" && _value.data !== null)
            {
                if(typeof _value.data.message !== "undefined" && _value.data.message !== null && _value.data.message.length > 0)
                {
                    _arrMsg.push(_value.data.message);
                }
                else if(typeof _value.data.errorMessage !== "undefined" && _value.data.errorMessage !== null && _value.data.errorMessage.length > 0)
                {
                    _arrMsg.push(_value.data.errorMessage);
                }
                else if(typeof _value.data.messages !== "undefined" && _value.data.messages !== null && _value.data.messages.length > 0)
                {
                    _arrMsg = _value.data.messages;
                }
                else
                {
                    _arrMsg.push("An error occurred, please try again later.");
                }

            }
            else if(typeof _value.message !== "undefined" && _value.message !== null)
            {
                _arrMsg.push(_value.message);
            }
            else if(typeof _value.errorMessage !== "undefined" && _value.errorMessage !== null && _value.errorMessage.length > 0)
            {
                _arrMsg.push(_value.errorMessage);
            }

            else
            {
                window.console.log("Unhandled-Error------");
                window.console.log(JSON.stringify(_value));
                _arrMsg.push("Unhandled Error - An error occurred, please try again later.");
            }
            commit("SET_MESSAGES", _arrMsg);
        },
    },
    getters: {

    },
    modules: {
        registration
    },
});