import axios from "axios";

const http = axios.create({
  /*TODO: Setup dev base url when ready*/
  /*Dev Url*/
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        "Content-Type": "application/json",
        "gateway": process.env.VUE_APP_SECRET,
    },
});

http.interceptors.response.use((response)=>{
      /*console.log("* Response"+JSON.stringify(response));*/
        if(response.data.success === false || [200,201,202,204].indexOf(response.status)<0)
        {
            return Promise.reject(response);
        }
        return response
    },
    (error)=>{
        window.console.log("Error* ", error);
        let _errorMsg = "An error occurred, please contact the System administration.";
        if(typeof error.message !== "undefined" && error.message !== null && error.message.length > 0)
        {
            _errorMsg = error.message;
        }
        else if(typeof error.errorMessage !== "undefined" && error.errorMessage !== null && error.errorMessage.length > 0)
        {
            _errorMsg = error.errorMessage;
        }
        let response = {
            data: {
                messages : [_errorMsg]
            },
            message: _errorMsg,
        };

      /*console.log("* Error Response"+JSON.stringify(error));*/
        return Promise.reject(response);
    }
);
export default http;
